<template>
  <todos-list />
</template>

<script>
import TodosList from "../components/TodosList";

export default {
  name: "Home",
  components: {
    TodosList
  }
};
</script>
