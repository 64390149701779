<template>
  <todo />
</template>

<script>
import Todo from "../components/Todo.vue";

export default {
  name: "TodoDetail",
  components: { Todo }
};
</script>

<style></style>
